import React from 'react'

import Loader from '../../Components/Loader/Loader'

import PlaylistView from '../../Components/Playlists/PlaylistView'
import { playlistSortByPosition } from '../../Helpers/sortArray'

import { INTERNAL_PLAYLIST_GROUP_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function InternalContainerPlaylistsScreen({ route, navigation }) {
	const api = useAxios()
	const [loading, setLoading] = React.useState(true)
	const [playlists, setPlaylists] = React.useState([])
	const categoryId = route.params ? route.params.categoryId : global.routeParams.categoryId
	const categoryName = route.params ? route.params.categoryName : global.routeParams.categoryName

	React.useEffect(() => {
		async function boot() {
			setLoading(true)
			const { data } = await api.get(INTERNAL_PLAYLIST_GROUP_URL + categoryId)
			let newData = playlistSortByPosition(data, false)
			setPlaylists(newData)
			setLoading(false)
		}
		boot()
	}, [])

	const handlePlaylistPress = (playlistId, photo, title, description) => {
		navigation.navigate('TracksScreen', { playlistId, photo, title, isTracksRemovable: false, isTrackPositionChangeable: true, description })
	}

	return loading ? (
		<Loader />
	) : (
		<PlaylistView playlists={playlists} handlePlaylistPress={handlePlaylistPress} hideResetBtn={true} categoryName={categoryName} />
	)
}
