import React, { useLayoutEffect, useState, useEffect } from 'react'
import { SafeAreaView, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import Sidebar from './Sidebar/Sidebar'
import { Platform } from 'react-native'
import useLayout from '../../Hooks/useLayout'

export default function MainLayout({ children }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const [padding, setPadding] = useState(0)
	const { isSidebarOpen } = useLayout()
	
	useLayoutEffect(() => {
		if (Platform.OS === 'android') setPadding(15)
		if (Platform.OS === 'ios') setPadding(30)
		if (Platform.OS === 'web') setPadding(0)
	}, [])

	const getChildSectionWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '72%'
			}

			return '78%'
		} else {
			if (isSidebarOpen) {
				return '60%'
			}

			return '85%'
		}
	}

	return (
		<View style={{
			backgroundColor: colors.primary,
			paddingTop: padding + 10,
			flex: 1
		}}>
			<View style={{ flexDirection: 'row', flex: 1 }}>
				<Sidebar />
				<View style={{ width: getChildSectionWidth(), paddingRight: 10 }}>{children}</View>
			</View>
		</View>
	)
}
