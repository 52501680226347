import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React, { useState, useEffect } from 'react'
import { Image, Platform, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import uuid from 'react-native-uuid'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { Input, TextArea } from '../../Components/Forms/Input'
import { Select } from '../../Components/Forms/Select'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { DataURIToBlob } from '../../Helpers/convertFile'
import { CREATE_PLAYLIST_URL, USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'
import { Dropdown } from '../../Components/Forms/Dropdown'

export default function CreatePlaylistScreen({
	setModalVisible = undefined,
	playlistInfo,
	processType = 'add',
	updateUserPlaylists,
	addToPlaylist = undefined,
	trackId,
	albumDetails = undefined
}) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const [name, setName] = useState(playlistInfo ? playlistInfo.name : '')
	const [message, setMessage] = useState({ error: false, message: '' })
	const [description, setDescription] = useState(playlistInfo ? playlistInfo.description : '')
	const [theme, setTheme] = useState(playlistInfo ? playlistInfo.theme : '')
	const [visibility, setVisibility] = useState(playlistInfo ? playlistInfo.visibility : '0')
	const visibilityList = [
		{
			value: 0,
			label: 'Privat'
		},
		{
			value: 1,
			label: 'Intern'
		}
	]

	const [loading, setLoading] = useState(false)
	const [Photo, setPhoto] = useState(null)
	const { getTokens } = useTokens()
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [photoShow, setPhotoShow] = useState(null)

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			allowsEditing: false,
			aspect: [4, 3],
			quality: 1
		})

		if (result.canceled) {
			return
		}

		let localUri = result.assets[0].uri
		setPhotoShow(localUri)
		setPhoto(result.assets[0])

		// if (!result.canceled) {
		// 	let uri = result.assets[0].uri

		// 	if (uri) {
		// 		setPhoto(Platform.OS === 'ios' ? uri.replace('file://', '') : uri)
		// 	} else {
		// 		const extension = uri.substring(uri.lastIndexOf('.') + 1, uri.length)
		// 		const type = result.type + '/' + extension
		// 		const name = 'test'
		// 		setPhoto({ uri, type, name })
		// 	}
		// }
	}

	// const fetchImageFromUri = async uri => {
	// 	const response = await fetch(uri)
	// 	const blob = await response.blob()
	// 	return blob
	// }

	// function DataURIToBlob(dataURI) {
	// 	const splitDataURI = dataURI.split(',')
	// 	const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
	// 	const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

	// 	const ia = new Uint8Array(byteString.length)
	// 	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

	// 	return new Blob([ia], { type: mimeString })
	// }

	const handleSubmit = async () => {
		setLoading(true)

		if (!name.trim() || !description.trim()) {
			setMessage({ error: true, message: 'Udfyld venligst alle felter' })
			setLoading(false)
			return
		}

		const { access } = await getTokens()
		const formData = new FormData()
		if (photoShow !== null) {
			if (Platform.OS === 'web') {
				const file = DataURIToBlob(photoShow)
				formData.append('Photo', file, uuid.v4() + '.jpg')
			}
			else {
				var photo = {
					uri: Photo.uri,
					type: 'image/jpeg',
					name: uuid.v4() + '.jpg',
				};
				formData.append('Photo', photo);
			}
		}
		formData.append('name', name)
		formData.append('description', description)
		formData.append('theme', theme)
		formData.append('visibility', String(visibility))
		axios
			.post(CREATE_PLAYLIST_URL, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					console.log('ADDED')
					setName('')
					setDescription('')
					setPhoto(null)
					if (updateUserPlaylists) {
						let photoUri = Photo ? Photo.uri : (playlistInfo ? (playlistInfo.Photo ? playlistInfo.Photo : (Photo ? Photo.uri : null)) : (Photo ? Photo.uri : null))
						updateUserPlaylists(response.data.id, name, description, visibility, photoUri)
					} 
					
					if (addToPlaylist && trackId && !albumDetails) {
						addToPlaylist(response.data.id, trackId)
					} else if (addToPlaylist && albumDetails && !trackId) {
						addToPlaylist(response.data.id, albumDetails)
					} else {
						openNotifModal('Opret spilleliste', 'Tilføjet')
					}
				} else {
					console.log('FAILED')
					openNotifModal('Opret spilleliste', 'Kunne ikke tilføje')
				}
				setName('')
				setDescription('')
			})
			.catch(err => {
				setMessage({ error: true, message: 'Noget gik galt' })
				// alert(CREATE_PLAYLIST_URL)
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const onChangeVisibility = selected => {
		// alert(typeof(selected))
		setVisibility(String(selected))
	}

	const handleUpdate = async () => {
		setLoading(true)

		if (!name.trim() || !description.trim()) {
			setMessage({ error: true, message: 'Udfyld venligst alle felter' })
			setLoading(false)
			return
		}

		const { access } = await getTokens()
		const formData = new FormData()

		if (photoShow !== null) {
			if (Platform.OS === 'web') {
				const file = DataURIToBlob(photoShow)
				formData.append('Photo', file, uuid.v4() + '.jpg')
			}
			else {
				var photo = {
					uri: Photo.uri,
					type: 'image/jpeg',
					name: uuid.v4() + '.jpg',
				};
				formData.append('Photo', photo);
			}
		}
		formData.append('name', name)
		formData.append('description', description)
		formData.append('theme', theme ? theme : null)
		formData.append('visibility', String(visibility))
		axios
			.put(USER_PLAYLIST_URL + '/' + playlistInfo.id, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					console.log('UPDATED')
					openNotifModal('Ændringer gemt', 'Gem ændringer')
					if (updateUserPlaylists) {
						let photoUri = Photo ? Photo.uri : (playlistInfo ? (playlistInfo.Photo ? playlistInfo.Photo : (Photo ? Photo.uri : null)) : (Photo ? Photo.uri : null))
						updateUserPlaylists(playlistInfo.id, name, description, visibility, photoUri)
					}
					setName('')
					setDescription('')
					setPhoto(null)
				} else {
					console.log('FAILED')
					openNotifModal('Ændringer gemt', 'Kunne ikke gemme')
				}
			})
			.catch(err => {
				console.log(err)
				setMessage({ error: true, message: 'Noget gik galt' })
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<>
			<ScrollView style={{ paddingRight: isWideScreen && !setModalVisible ? '50%' : 5 }}>
				<Input placeholder={'Navn'} onChangeText={text => setName(text)} value={name} />
				<View>
					<TextArea
						placeholder={'Beskrivelse'}
						numberOfLines={4}
						onChangeText={text => setDescription(text)}
						style={{ width: '100%', backgroundColor: colors.primary, height: 60 }}
						value={description}
					/>
				</View>
				<PrimaryButton title={'Vælg et billede fra kamerarullen'} onPress={pickImage} />
				{(Photo || playlistInfo) && (
					<Image
						source={{ uri: Photo ? Photo.uri : (playlistInfo ? (playlistInfo.Photo ? playlistInfo.Photo : (Photo ? Photo.uri : null)) : (Photo ? Photo.uri : null)) }}
						style={{
							width: '100%',
							height: isWideScreen ? 400 : (isSmallScreen ? 250 : 300),
							marginBottom: 10,
							borderWidth: 1,
							borderColor: 'gray'
						}}
					/>
				)}
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, width: '100%' }}>
					<View style={{ width: isWideScreen ? '20%' : '30%' }}>
						<Text style={{ color: 'white' }}>Synlighed</Text>
					</View>
					{/* <Select onValueChange={onChangeVisibility} value={visibility} items={visibilityList} /> */}
					<View style={{ width: isWideScreen ? '80%' : '70%' }}>
						<Dropdown
							onValueChange={onChangeVisibility}
							value={parseInt(visibility)}
							items={visibilityList}
							width={'100%'}
							marginLeft={0}
							key={'visibilityidkey'}
						/>
					</View>
				</View>
				{!setModalVisible && <PrimaryButton
					title={processType === 'add' ? 'Opret Spilleliste' : 'Gem'}
					loading={loading}
					onPress={() => {
						processType === 'add' ? handleSubmit() : handleUpdate()
					}}
					style={{ width: '100%' }}
				/>}
			</ScrollView>

			{setModalVisible && <PrimaryButton
				title={processType === 'add' ? 'Opret Spilleliste' : 'Gem'}
				loading={loading}
				onPress={() => {
					processType === 'add' ? handleSubmit() : handleUpdate()
				}}
				style={{ width: '100%' }}
			/>}

			{setModalVisible && <SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} />}

			<Text style={{ textAlign: 'center', color: message.error ? 'red' : 'green', marginVertical: 10 }}>
				{message.message}
			</Text>

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={setModalVisible}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</>
	)
}
