import { Ionicons } from '@expo/vector-icons'
import { useEffect, useState } from 'react'
import { Pressable, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { Filter } from '../../Components/Forms/Filter'
import Loader from '../../Components/Loader/Loader'
import { arrayListSortByKey } from '../../Helpers/sortArray'
import {
	SEARCH_ACCOMPANIMENT_URL,
	SEARCH_ALBUMS_URL,
	SEARCH_ARTISTS_URL,
	SEARCH_CONTAINERS_URL,
	SEARCH_GENRE_URL,
	SEARCH_PLAYLISTS_URL,
	SEARCH_TRACKS_URL,
	SEARCH_YEAR_URL,
	colors
} from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'
import SearchResultAlbum from './SearchResultAlbum'
import SearchResultArtist from './SearchResultArtist'
import SearchResultContainer from './SearchResultContainer'
import SearchResultGenre from './SearchResultGenre'
import SearchResultPlaylist from './SearchResultPlaylist'
import SearchResultTrack from './SearchResultTrack'
import SearchResultYear from './SearchResultYear'
import { SearchFilters } from './components/SearchFilters'
import SearchResultAccompaniment from './SearchResultAccompaniment'

export default function SearchScreen({ route, navigation }) {
	const { width } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)

	const handleResize = () => {
		setIsWideScreen(width > 700)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	const [searchResults, setSearchResults] = useState({
		Albums: [],
		Artists: [],
		Tracks: [],
		Genre: [],
		Year: [],
		Playlists: [],
		Containers: []
	})
	const [loading, setLoading] = useState(true)
	const artistLabel = 'Kunstnere'
	const tracksLabel = 'Sange'
	const albumLabel = 'Albums'
	const yearLabel = 'År'
	const genreLabel = 'Genre'
	const allLabel = 'Alle'
	const playlistLabel = 'Spillelister'
	const categoryLabel = 'Kategorier'
	const accompanimentLabel = 'Akkompagnement til fællessang'
	const requestSong = 'Anmod om sange'

	const [selectedCategory, setSelectedCategory] = useState(undefined)
	const [searchInput, setSearchInput] = useState('')
	const [categoryFilters, setCategoryFilters] = useState([])

	const { isShowSearchBox, setIsShowSearchBox } = useLayout()

	const api = useAxios()

	const standardValueLabel = 'standard'
	const alphabeticalValueLabel = 'alphabetical'
	const [sortValue, setSortValue] = useState('Skift')
	const sortByValues = [{
		value: standardValueLabel,
		label: 'Standard'
	}, {
		value: alphabeticalValueLabel,
		label: 'Alfabetisk'
	}]
	const [originalData, setOriginalData] = useState(undefined)

	useEffect(() => {
		async function boot() {
			setLoading(true)
			if (categoryFilters.length > 0) {
				let categoryTemp = categoryFilters[0].name === allLabel ? categoryFilters[1].name : categoryFilters[0].name
				getSearchResults(categoryTemp)
				setSelectedCategory(categoryTemp)
			}
			// setLoading(false)
		}
		boot()
	}, [searchInput, categoryFilters])

	useEffect(() => {
		setLoading(true)
		getSearchResults()
	}, [selectedCategory])

	useEffect(() => {
		setLoading(false)
	}, [])

	const handleSearch = (text, categoryFilters) => {
		setSearchInput(text)
		setCategoryFilters(categoryFilters)
	}

	const getUrlWithYearGenre = url => {
		let yearIndex = categoryFilters.findIndex(x => x.name === yearLabel)
		if (yearIndex > -1) {
			if (searchInput) url = url + '+'
			url = url + categoryFilters[yearIndex].yearRange
		}

		let genreIndex = categoryFilters.findIndex(x => x.name === genreLabel)
		if (genreIndex > -1) {
			if (searchInput) url = url + '+'
			url = url + categoryFilters[yearIndex].genre
		}
		return url
	}

	const getSearchResults = async (categoryTemp = selectedCategory) => {
		let url = ''
		let newSelectedCategory = categoryTemp === allLabel ? categoryFilters[1].name : categoryTemp

		if (newSelectedCategory === tracksLabel) {
			url = SEARCH_TRACKS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === artistLabel) {
			url = SEARCH_ARTISTS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === albumLabel) {
			url = SEARCH_ALBUMS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === genreLabel) {
			url = SEARCH_GENRE_URL
			let genreIndex = categoryFilters.findIndex(x => x.name === genreLabel)
			if (genreIndex > -1) {
				url = url + (categoryFilters[genreIndex].genre === allLabel ? 'all' : categoryFilters[genreIndex].genre)
			}
		} else if (newSelectedCategory === yearLabel) {
			url = SEARCH_YEAR_URL
			let yearIndex = categoryFilters.findIndex(x => x.name === yearLabel)
			if (yearIndex > -1) {
				url = url + categoryFilters[yearIndex].yearRange
			}
		} else if (newSelectedCategory === playlistLabel) {
			url = SEARCH_PLAYLISTS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === categoryLabel) {
			url = SEARCH_CONTAINERS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === accompanimentLabel) {
			url = SEARCH_ACCOMPANIMENT_URL + searchInput
			// url = getUrlWithYearGenre(url)
		}
		if (url) {
			const { data } = await api.get(url)
			setOriginalData(data)
			setSearchResults(data)
			setLoading(false)
		}
	}

	const setItems = (data, sortBy = standardValueLabel) => {
		let sortedPlaylists = {}

		if (sortBy === standardValueLabel) {
			setLoading(true)
			getSearchResults()
		}
		else {
			if (selectedCategory === tracksLabel) {
				if (searchResults.Tracks.length > 0)
					sortedPlaylists.Tracks = arrayListSortByKey(searchResults.Tracks, "song_title")
				if (searchResults.MostRelated.length > 0)
					sortedPlaylists.MostRelated = arrayListSortByKey(searchResults.MostRelated, "song_title")
			} else if (selectedCategory === artistLabel) {
				if (searchResults.Artists.length > 0)
					sortedPlaylists.Artists = arrayListSortByKey(searchResults.Artists, "artist")
				if (searchResults.MostRelated.length > 0)
					sortedPlaylists.MostRelated = arrayListSortByKey(searchResults.MostRelated, "artist")
			} else if (selectedCategory === albumLabel) {
				if (searchResults.Albums.length > 0)
					sortedPlaylists.Albums = arrayListSortByKey(searchResults.Albums, "album")
				if (searchResults.MostRelated.length > 0)
					sortedPlaylists.MostRelated = arrayListSortByKey(searchResults.MostRelated, "album")
			} else if (selectedCategory === genreLabel) {
				if (searchResults.Genre.length > 0)
					sortedPlaylists.Genre = searchResults.Genre.sort()
			} else if (selectedCategory === yearLabel) {
				if (searchResults.Year.length > 0)
					sortedPlaylists.Year = searchResults.Year.sort()
			} else if (selectedCategory === playlistLabel) {
				if (searchResults.InternalPlaylists.length > 0)
					sortedPlaylists.InternalPlaylists = arrayListSortByKey(searchResults.InternalPlaylists, "playlist_name")
				if (searchResults.ContainerPlaylists.length > 0)
					sortedPlaylists.ContainerPlaylists = arrayListSortByKey(searchResults.ContainerPlaylists, "playlist_name")
				if (searchResults.Playlists.length > 0)
					sortedPlaylists.Playlists = arrayListSortByKey(searchResults.Playlists, "name")
			} else if (selectedCategory === categoryLabel) {
				if (searchResults.Containers.length > 0)
					sortedPlaylists.Containers = arrayListSortByKey(searchResults.Containers, "name")
			} else if (selectedCategory === accompanimentLabel) {
				if (searchResults.Tracks.length > 0)
					sortedPlaylists.Tracks = arrayListSortByKey(searchResults.Tracks, "song_title")
				if (searchResults.MostRelated.length > 0)
					sortedPlaylists.MostRelated = arrayListSortByKey(searchResults.MostRelated, "song_title")
			}
			setSearchResults(sortedPlaylists)
		}

	}

	const onSortValueChange = (e) => {
		setSortValue(e)
		setItems(originalData, e)
	}

	const TabLabel = ({ label, icon }) => {
		return (
			<Pressable
				onPress={() => {
					setSelectedCategory(label)
					setSortValue(standardValueLabel)
				}}
				style={{
					flexDirection: 'row',
					backgroundColor: selectedCategory === label ? colors.accent : colors.secondary,
					height: 35,
					borderRadius: 15,
					marginLeft: 5
				}}
			>
				<Ionicons
					name={icon}
					style={{ margin: 5 }}
					size={isWideScreen ? 20 : 16}
					color={selectedCategory === label ? 'white' : colors.accent}
				/>
				<Text
					style={{
						width: 'auto',
						marginTop: 3,
						color: selectedCategory === label ? 'white' : colors.accent,
						fontSize: isWideScreen ? 20 : 16,
						marginRight: 5
					}}
				>
					{label}
				</Text>
			</Pressable>
		)
	}

	const RequestSongView = () => {
		return (
			<View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
						marginBottom: isWideScreen ? 0 : 10
					}}
				>
					<View>
						<Text style={{ color: 'white', fontSize: isWideScreen ? 20 : 16 }}>{requestSong}</Text>
					</View>
					<View style={{ paddingLeft: 5 }}>
						<PrimaryButton
							style={{ width: isWideScreen ? 80 : 65, minHeight: 30, marginVertical: 0 }}
							title="Send"
							onPress={() => navigation.navigate('SongRequestScreen')}
						/>
					</View>
				</View>
			</View>
		)
	}

	return (
		<ScrollView style={{ paddingHorizontal: 5, flex: 1, width: '100%' }}>
			<View>
				<View>
					<RequestSongView />
				</View>
				<View
					style={{
						borderColor: 'gray',
						borderBottomWidth: 3,
						borderRadius: 5,
						width: '100%'
					}}>
					<SearchFilters
						handleSearch={handleSearch}
						searchInput={searchInput}
						checkedCategoryFilters={categoryFilters}
						isShowSearchBox={isShowSearchBox}
						setIsShowSearchBox={setIsShowSearchBox}
					/>
				</View>
			</View>
			{loading ? (
				<Loader />
			) : (
				<>
					{isWideScreen ? (
						<ScrollView>
							<View style={{ marginTop: 5, paddingBottom: 80 }}>
								{/* <View style={{ display: 'flex', height: 'auto' }}> */}
								<View style={{ flexDirection: 'row', marginLeft: 5, justifyContent: 'space-between' }}>
									<View style={{ flexDirection: 'row' }}>
										{categoryFilters.map(
											item => item.name !== allLabel && <TabLabel key={item.name} icon={item.icon} label={item.name} />
										)}
									</View>
									{
										categoryFilters.length > 0 &&
										<Filter value={sortValue} items={sortByValues} onSortValueChange={onSortValueChange}
											defaultButtonText={sortValue} newItemLabelName={'Filter Feature'} />
									}
								</View>
								{selectedCategory === tracksLabel && (
									<SearchResultTrack tracks={searchResults.Tracks} mostRelated={searchResults.MostRelated} />
								)}
								{selectedCategory === artistLabel && (
									<SearchResultArtist
										artists={searchResults.Artists}
										navigation={navigation}
										mostRelated={searchResults.MostRelated}
									/>
								)}
								{selectedCategory === albumLabel && (
									<SearchResultAlbum
										albums={searchResults.Albums}
										navigation={navigation}
										mostRelated={searchResults.MostRelated}
									/>
								)}
								{selectedCategory === yearLabel && searchResults.Year && (
									<SearchResultYear year={searchResults.Year.filter(x => x.length === 4)} navigation={navigation} />
								)}
								{selectedCategory === genreLabel && (
									<SearchResultGenre genre={searchResults.Genre} navigation={navigation} />
								)}
								{selectedCategory === playlistLabel && (
									<View>

										{searchResults.ContainerPlaylists && searchResults.Playlists && searchResults.InternalPlaylists && (
											<View>
												{(searchResults.ContainerPlaylists.length === 0 && searchResults.Playlists.length === 0 && searchResults.InternalPlaylists.length === 0)
													&& <Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>}
											</View>
										)}
										<SearchResultPlaylist
											playlist={searchResults.ContainerPlaylists}
											navigation={navigation}
											title={'Kategorier spillelister'}
										/>
										<SearchResultPlaylist
											playlist={searchResults.Playlists}
											navigation={navigation}
											title={'Mine spillelister'}
											isShowNoDataLabel={false}
										/>
										<SearchResultPlaylist
											playlist={searchResults.InternalPlaylists}
											navigation={navigation}
											title={'Internt spillelister'}
											isShowNoDataLabel={false}
										/>
									</View>
								)}
								{selectedCategory === categoryLabel && (
									<SearchResultContainer containers={searchResults.Containers} navigation={navigation} />
								)}
								{selectedCategory === accompanimentLabel && (
									<SearchResultAccompaniment tracks={searchResults.Tracks} mostRelated={[]} isShowHeader={false} />
								)}
							</View>
						</ScrollView>
					) : (
						<View style={{ marginTop: 5, paddingBottom: 40 }}>
							<ScrollView horizontal={true} style={{ flexDirection: 'row', marginLeft: 5 }}>
								<View style={{ flexDirection: 'row' }}>
									{categoryFilters.map(
										item => item.name !== allLabel && <TabLabel key={item.name} icon={item.icon} label={item.name} />
									)}
								</View>
								{
									categoryFilters.length > 0 &&
									<Filter value={sortValue} items={sortByValues} onSortValueChange={onSortValueChange}
										defaultButtonText={sortValue} newItemLabelName={'Filter Feature'} />
								}
							</ScrollView>
							<ScrollView horizontal={false} >
								<View>
									{selectedCategory === tracksLabel && (
										<SearchResultTrack tracks={searchResults.Tracks} mostRelated={searchResults.MostRelated} />
									)}
									{selectedCategory === artistLabel && (
										<SearchResultArtist
											artists={searchResults.Artists}
											navigation={navigation}
											mostRelated={searchResults.MostRelated}
										/>
									)}
									{selectedCategory === albumLabel && (
										<SearchResultAlbum
											albums={searchResults.Albums}
											navigation={navigation}
											mostRelated={searchResults.MostRelated}
										/>
									)}
									{selectedCategory === yearLabel && searchResults.Year && (
										<SearchResultYear year={searchResults.Year.filter(x => x.length === 4)} navigation={navigation} />
									)}
									{selectedCategory === genreLabel && (
										<SearchResultGenre genre={searchResults.Genre} navigation={navigation} />
									)}
									{selectedCategory === playlistLabel && (
										<View>
											<SearchResultPlaylist
												playlist={searchResults.ContainerPlaylists}
												navigation={navigation}
												title={'Kategorier spillelister'}
											/>
											<SearchResultPlaylist
												playlist={searchResults.Playlists}
												navigation={navigation}
												title={'Mine spillelister'}
												isShowNoDataLabel={false}
											/>
											<SearchResultPlaylist
												playlist={searchResults.InternalPlaylists}
												navigation={navigation}
												title={'Internt spillelister'}
												isShowNoDataLabel={false}
											/>
										</View>
									)}
									{selectedCategory === categoryLabel && (
										<SearchResultContainer containers={searchResults.Containers} navigation={navigation} />
									)}
									{selectedCategory === accompanimentLabel && (
										<SearchResultAccompaniment tracks={searchResults.Tracks} mostRelated={[]} isShowHeader={false} />
									)}
								</View>
							</ScrollView>
						</View>
					)}
				</>
			)}
		</ScrollView>
	)
}
