import { Ionicons } from '@expo/vector-icons'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Image, Pressable, StyleSheet, Text, useWindowDimensions, View } from 'react-native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { captureException } from '../../Helpers/sentryLog'
import { colors, USER_PLAYLIST_URL, THIRD_PARTY_INFO_TEXT } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import { Dropdown } from '../Forms/Dropdown'
import { Input } from '../Forms/Input'
import RadioButtonGroup from '../Forms/RadioButtonGroup'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import Papa from 'papaparse'
import { InfoButton } from '../Buttons/Button'
import { Popup } from '../../Components/Playlists/Popup'
import { ImportHelpModal } from './ImportHelpModal'
import { deviceType } from 'expo-device'
import { TextDecoder } from 'text-encoding'
import useLayout from '../../Hooks/useLayout'

export const ImportPlaylistModal = ({
	importObject,
	handleSubmit,
	infoMessage
}) => {

	const spotifyLabel = 'Spotify'
	const youtubeLabel = 'Youtube'
	const csvLabel = 'CSV File'
	const appleLabel = 'Apple Music'
	const platformOptions = [
		{
			id: 1,
			value: spotifyLabel,
			imageLink: require('../../assets/music-platforms/spotify-logo.png'),
		},
		// {
		// 	id: 2,
		// 	value: youtubeLabel,
		// 	imageLink: require('../../assets/music-platforms/youtube-logo.png'),
		// }
		{
			id: 3,
			value: csvLabel,
			imageLink: require('../../assets/music-platforms/csv-logo.png'),
		}
	]

	const importOptions = [
		{
			id: 1,
			label: 'Opret Spilleliste',
			value: 'true'
		},
		{
			id: 2,
			label: 'Importer til eksisterende spilleliste:',
			value: 'false'
		}
	]

	const api = useAxios()
	const [accessToken, setAccessToken] = useState('')
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700 && height > 1000)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(true)
	const [userPlaylist, setUserPlaylist] = useState([])
	const [disablePlaylistDropdown, setDisablePlaylistDropdown] = useState(true)
	const [hasError, setHasError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [importOption, setImportOption] = useState(importObject.importOption)
	const [selectedSourcePlatform, setSelectedSourcePlatform] = useState(importObject.sourcePlatform)
	const [sourcePlatform, setSourcePlatform] = useState(importObject.sourcePlatform)
	const [destinationPlaylist, setDestinationPlaylist] = useState(importObject.destinationPlaylist)
	const [sourcePlaylistNames, setSourcePlaylistNames] = useState(importObject.sourcePlaylistNames)
	const [selectedSourcePlaylist, setSelectedSourcePlaylist] = useState(importObject.sourcePlaylistValue)
	const [isPlaylistRetrieved, setIsPlaylistRetrieved] = useState(importObject.sourcePlaylistNames.length > 0)
	const [tracksRetrievedMessage, setTracksRetrievedMessage] = useState(importObject.tracksRetrievedMessage)
	const [playlistRetrievedMessage, setPlaylistRetrievedMessage] = useState(importObject.playlistRetrievedMessage)
	const [selectedFile, setSelectedFile] = useState(importObject.selectedFile)
	const [username, setUsername] = useState(importObject.username)
	const [requestedUsername, setRequestedUsername] = useState(importObject.username)
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [modalVisible, setModalVisible] = useState(false)

	const [mainInfo, setMainInfo] = useState([])
	const [importPlaylist, setImportPlaylist] = useState([])
	const [spotifyPlaylist, setSpotifyPlaylist] = useState([])
	const [spotifyUsername, setSpotifyUsername] = useState([])
	const [csvPlaylist, setCSVPlaylist] = useState([])

	const [importPlaylistGuideVisible, setImportPlaylistGuideVisible] = useState(false)
	const [spotifyPlaylistGuideVisible, setSpotifyPlaylistGuideVisible] = useState(false)
	const [spotifyUsernameGuideVisible, setSpotifyUsernameGuideVisible] = useState(false)
	const [csvPlaylistGuideVisible, setCSVPlaylistGuideVisible] = useState(false)
	const { deviceType } = useLayout()

	const handleResize = () => {
		setIsWideScreen(width > 700 && height > 1000)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		getUserPlaylist()
		if (importOption === 0) {
			setDisablePlaylistDropdown(true)
		} else {
			setDisablePlaylistDropdown(false)
		}
	}, [importOption])

	useEffect(() => {
		const boot = async () => {
			await getAccessToken()
			getInfoMessages()
		}
		boot()
	}, [])

	// useEffect(() => {
	// 	getInfoMessages()
	// }, [])

	const modalStyle = StyleSheet.create({
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : 18,
			marginLeft: isWideScreen ? 20 : 0
		},
		sublabel: {
			color: 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: '5%',
		},
		innerSublabel: {
			color: disablePlaylistDropdown ? 'gray' : 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: '10%',
			marginTop: 10,
			marginBottom: 10
		},
		rules: {
			fontStyle: 'italic',
			color: 'white',
			fontSize: isWideScreen ? 12 : 10,
			marginRight: 10,
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 14,
			marginRight: '10%',
		},
		selectedIcon: {
			position: 'absolute',
			width: 28,
			height: 28,
			zIndex: 1,
			marginTop: 20,
			backgroundColor: colors.accent,
			borderRadius: 60
		}
	})
	const [fileUri, setFileUri] = useState(null);
	const [csvData, setCsvData] = useState([]);
	const [filePicked, setFilePicked] = useState(false);
	const [fileResult, setFileResult] = useState([])

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const closeGuideModal = (visible) => {
		setGuideModal({
			visible: visible,
			title: '',
			description: ''
		})
	}

	const openNotifModal = (description) => {
		setNotifModal({
			visible: true,
			title: '',
			description: description,
			isDisappearing: false
		})
	}

	const getInfoMessages = () => {
		setLoading(true)
		api
			.get(THIRD_PARTY_INFO_TEXT)
			.then(
				({ data }) => {
					//// static data - comment out if unused
					// const staticData = [{
					// 	active_status: true,
					// 	creation_date: "2024-09-06T08:58:26.640126+02:00",
					// 	description: 
					// 	`\r\nDenne funktion har til hensigt at gøre det let og hurtigt at importere Spotify og Excel ark spillelister til MusicMind.
					// 	\r\n(senere følger YouTube og andre store udbydere).
					// 	\r\nNumre som ikke findes i vores arkiv, vil blive indkøbt og gjort tilgængelig i spillelisten. Dette vil man blive gjort opmærksom på når det er udført indenfor maksimum 5 arbejdsdage.
					// 	\r\n\r\n\r\nProcessen forløber således:
					// 	\r\n\r\nImporter spilleliste
					// 	\r\nHer skal du først vælge om du vil importere til en eksisterende spilleliste eller til en ny. Herefter skal du vælge om du vil importere fra Spotify eller fra en Excel CSV-fil
					// 	\r\n\r\n\r\nSpotify spilleliste
					// 	\r\nHvis du vælger Spotify, skal du skrive dit brugernavn. 
					// 	\r\n- I appen finder du det under ”I” symbolet som du finder øverst oppe til venstre. Herefter klikker du på ”indstillinger og privatliv” som du finder nederst i menuen. Herefter klikker du på ”Konto” og herefter er du i menuen hvor du kan se dit brugernavn under ”konto” overskriften.
					// 	\r\n- på hjemmesiden finder du brugernavnet øverst oppe til højre i”I” symbolet. Klik på det. Vælg ”konto”. Du ser en ny side og her finder du menuen ”Rediger profil” yders til højre. Du ser en ny side hvor du nu finder dit brugernavn under menuen ”Rediger profil”.
					// 	\r\n\r\n\r\nSpotify Brugernavn
					// 	\r\nSpotify brugernavnet skriver du i det sorte felt ud fra knappen ”Modtag spilleliste”. Klik på knappen og processen sættes i gang. Når den er færdig, klikker du på feltet til højre for ”Importer spilleliste” hvorefter du vil se alle dine spillelister. Vælg den spilleliste du ønsker at importere. Klik på ”Importer spilleliste” knappen. Du ser et vindue hvor der står hvor mange numre der er hentet. Klik ”ok”. Klik på ”næste” knappen. MusicMind viser nu hvor mange numre der er i vores arkiv. Tryk på ”Gem spilleliste” og Klik på ”Næste” knappen. Herefter ser du hvor mange numre vi ikke har i vores arkiv. Vælg dem du ønsker vi skal importere. Klik på ”forespørg på sange” knappen. Processen sættes i gang.
					// 	\r\n\r\n\r\nExcel ark spilleliste
					// 	\r\nVælger du at importere en spilleliste via en CSV-fil. Bliver du bedt om at vælge Excel filen på din computer. Filen skal indeholde 3 kolonner med følgende informationer. 
					// 	\r\n1: Titel, 2: kunstner, 3: Album. Klik herefter ”importer spilleliste”. 
					// 	\r\nEt pop-up vindue fortæller hvor mange numre der er hentet. Klik ”ok”, ”Importer spilleliste” og derefter ”Næste”. Du vil se hvilke numre der findes i arkivet. Klik på ”gem spilleliste”. Et pop-up vindue viser sig. Klik ”ok” og ”næste”. Du ser hvilke sange der ikke findes i arkivet. Vælg dem du ønsker vi skal hente. Skriv din e-mailadresse og eventuelt en besked og afslut din forespørgsel ved at klikke på ”Forespørg på numre”. Afslut ved at klikke på knappen ”afslut”
					// 	`,
					// 	id: 1,
					// 	name: "General Information"
					// }]

					infoMessage(data.Results)
					setMainInfo(data.Results.filter(x => x.code.includes('mainInfo')))
					setImportPlaylist(data.Results.filter(x => x.code.includes('importPlaylist')))
					setSpotifyPlaylist(data.Results.filter(x => x.code.includes('spotifyPlaylist')))
					setSpotifyUsername(data.Results.filter(x => x.code.includes('spotifyUsername')))
					setCSVPlaylist(data.Results.filter(x => x.code.includes('csvPlaylist')))
				},
				({ error }) => {
					console.log(error)
					captureException(error)
				}
			)
			.catch(error => {
				captureException(error)
			})
			.finally(() => {
				setLoading(false)
			}
			)
	}

	const getUserPlaylist = async () => {
		setLoading(true)
		let newItems = []
		
		await api
			.get(USER_PLAYLIST_URL)
			.then(({ data }) => {
				data = data.sort(function (a, b) {
					return a.id - b.id
				})

				data.map((item, index) => {
					const exists = newItems.find(x => x.label === item.name)
					if (!exists) {
						newItems.push({
							value: item.id,
							label: item.name
						})
					}
					if (index === data.length - 1) {
						setUserPlaylist(newItems)
					}
				})
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => {
				console.log(userPlaylist)
				setLoading(false)
			})
	}

	const getAccessToken = async () => {
		//ANN
		const CLIENT_ID = '0778c3a58b444d9cbc643c8095aded1a'
		const CLIENT_SECRET = '7cfccabaf6164788866fa497c330c453'

		//LEVI
		// const CLIENT_ID = 'da2101a6e445494b9b28fcf7d57c253a'
		// const CLIENT_SECRET = 'a6b612546cd64e718b58a8471b130a8b'

		//USERNAMES FOR TEST
		// const USER_NAME = '31va3jwb6yw5gokfrqqgo6z7budm' //LEVI
		// const USER_NAME = '12140947399' //YU
		// const USER_NAME = 'chargebeam' //RANDOM FROM INTERNET

		const auth = {
			headers: {
				'Content-type': 'application/x-www-form-urlencoded'
			},
		}

		await axios.post('https://accounts.spotify.com/api/token', 'grant_type=client_credentials&client_id='
			+ CLIENT_ID + '&client_secret=' + CLIENT_SECRET, auth)
			// .then(result => result.json())
			.then(response => setAccessToken(response.data.access_token))
	}

	const onChangeDestinationPlaylist = e => {
		if (e !== '') {
			setDestinationPlaylist(parseInt(e, 10))
		}
	}

	const onChangeSourcePlaylist = e => {
		if (e !== '') {
			setSelectedSourcePlaylist(e)
		}
	}

	const getPlatformPlaylists = async () => {
		setLoading(true)
		setHasError(false)
		setErrorMessage('')
		setPlaylistRetrievedMessage('')

		if (username.trim() === '') {
			handleError('Venligst intast dit ' + selectedSourcePlatform + '  brugernavn.')
			return
		}

		if (selectedSourcePlatform === spotifyLabel) {
			setRequestedUsername(username)
			setSourcePlatform(selectedSourcePlatform)

			await axios.get('https://api.spotify.com/v1/users/' + username + '/playlists', getAuthParameters('GET'))
				.then(response => {
					let data = response.data
					if (data) {
						const playlistCount = data?.items?.length
						if (data?.error) {
							if (data?.error?.status === 404) {
								handleError('Import failed: ' + sourcePlatform + ' user ' + username + ' not found.')
								return
							} else {
								handleError('Import failed: ' + data?.error?.message)
								return
							}
						} else if (playlistCount === 0) {
							setPlaylistRetrievedMessage('Ingen playlister hentet. Prøv venligst igen.')
							handleError(true)
							return
						} else {
							if (playlistCount === 0) {
							} else if (playlistCount === 1) {
								setPlaylistRetrievedMessage('Hentet 1 Playlist.')
							} else {
								setPlaylistRetrievedMessage('Hentet ' + playlistCount + ' playlister. Vælg en playlist for at importere.')
							}
							mapPlaylistResponse(data)
						}
					} else {
						handleError('Import failed: No response received.')
						return
					}
				}, (rejected) => {
					const err = rejected?.response?.data?.error?.message ?? 'Bad Request'
					handleError('Import failed: ' + err)
					console.log(rejected)
				})
		} else {
			handleError('Importing from ' + sourcePlatform + ' is not yet available.')
			return
		}
	}

	const getPlatformTracks = async () => {
		setLoading(true)
		setHasError(false)
		setErrorMessage('')
		setTracksRetrievedMessage('')

		if (sourcePlatform === spotifyLabel) {

			let playlistName = ''
			let trackLink = ''
			sourcePlaylistNames?.map(playlist => {
				if (playlist.value === selectedSourcePlaylist) {
					playlistName = playlist.label
					trackLink = playlist.tracks
				}
			})

			if (importOption === 1) {
				playlistName = userPlaylist.find(x => x.value === destinationPlaylist).label
			}

			await axios.get(trackLink, getAuthParameters('GET'))
				.then(response => {
					let msg = ''
					let data = response.data
					if (data) {
						const tracksCount = data?.items?.length
						if (data?.error) {
							if (data?.error?.status === 404) {
								handleError('Import failed: Tracks for ' + sourcePlatform + ' user ' + username + ' not found.')
								return
							} else {
								handleError('Import failed: ' + data?.error?.message)
								return
							}
						} else if (tracksCount === 0) {
							msg = 'Ingen Numre hentet fra den valgte playlist. Prøv venligst igen.'
							handleError(true)
							return
						} else {
							setLoading(false)
							if (tracksCount === 1) {
								msg = 'Hentet 1 Nummer. Klik på Næste for at fortsætte.'
							} else {
								msg = 'Hentet ' + tracksCount + ' Numre. Klik på Næste for at fortsætte.'
							}

							openNotifModal(msg)

							handleSubmit({
								isInitial: false,
								importOption: importOption,
								sourcePlatform: sourcePlatform,
								destinationPlaylist: destinationPlaylist,
								sourcePlaylistNames: sourcePlaylistNames,
								sourcePlaylistValue: selectedSourcePlaylist,
								playlistName: playlistName,
								tracksResponse: data,
								tracksCount: tracksCount,
								tracksRetrievedMessage: msg,
								playlistRetrievedMessage: playlistRetrievedMessage,
								username: requestedUsername
							})
						}
					} else {
						handleError('Import failed: No response received.')
						return
					}

					setTracksRetrievedMessage(msg)
				})
		} else {
			handleError('Importing Tracks from ' + sourcePlatform + ' is not yet available.')
			return
		}
	}

	const handleError = (errorMessage) => {
		setHasError(true)
		setErrorMessage(errorMessage)
		openNotifModal(errorMessage)
		setLoading(false)
		setIsPlaylistRetrieved(false)
	}

	const mapPlaylistResponse = (data) => {
		let newItems = []
		data?.items?.map(item => {
			newItems.push({
				value: item.id,
				label: item.name,
				tracks: item.tracks.href
			})
		})

		setIsPlaylistRetrieved(newItems.length > 0)
		setSourcePlaylistNames(newItems)
		setLoading(false)
	}

	const getAuthParameters = (methodType) => {
		return {
			method: methodType,
			headers: {
				'Content-type': 'application/json',
				'Authorization': 'Bearer ' + accessToken
			}
		}
	}

	const pickDocument = async () => {
		try {
			const result = await DocumentPicker.getDocumentAsync({ type: 'text/csv' });
			if (result.canceled === false) {
				setSourcePlatform(selectedSourcePlatform)
				setFileUri(result.assets[0].uri);
				if (deviceType === 1) {
					setSelectedFile(result.assets[0].name)
				} else {
					setSelectedFile(result.assets[0].file.name)
				}
				setFileResult(result)
			}
		} catch (error) {
			alert('Kunne ikke læse dokumentet');
			console.error("Error picking document:", error);
		}
	};

	const readCSVTracks = async () => {
		setLoading(true)
		
		const uri = fileResult.assets[0].uri
		await fetch(uri,
			{
				headers: {"Content-Type": "text/html"}
			}
		)
		.then(response => response.arrayBuffer())
		.then(buffer => {

			let decoder = new TextDecoder("iso-8859-1")
			let text = decoder.decode(buffer)
			if (text) {
				const parsedData = Papa.parse(text, {
					header: true,
					skipEmptyLines: true});
				const tracks = parsedData.data
				setCsvData(tracks);
				setFilePicked(true);
				if (!parsedData || parsedData.errors.length > 0) {
					setLoading(false)
					console.error('Error parsing CSV: ', parsedData.errors);
				} else {
					let msg = ''
					let playlistName = ''
					const tracksCount = tracks.length
					if (tracksCount === 0) {
						msg = 'Ingen Numre hentet fra den valgte playlist. Prøv venligst igen.'
						setLoading(false)
						handleError(true)
						return
					} else {

						if (importOption === 0) {
							playlistName = selectedFile.replace('.csv', '')
						} else {
							playlistName = userPlaylist.find(x => x.value === destinationPlaylist).label
						}

						if (tracksCount === 1) {
							msg = 'Hentet 1 Nummer. Klik på Næste for at fortsætte.'
						} else {
							msg = 'Hentet ' + tracksCount + ' Numre. Klik på Næste for at fortsætte.'
						}

						openNotifModal(msg)

						handleSubmit({
							isInitial: false,
							importOption: importOption,
							sourcePlatform: sourcePlatform,
							destinationPlaylist: destinationPlaylist,
							sourcePlaylistNames: [],
							sourcePlaylistValue: '',
							playlistName: playlistName,
							tracksResponse: tracks,
							tracksCount: tracksCount,
							tracksRetrievedMessage: msg,
							playlistRetrievedMessage: '',
							username: ''
						})
						setTracksRetrievedMessage(msg)
						setLoading(false)
					}
				}
			} else {
				setLoading(false)
				alert('Kunne ikke læse fildata');
				console.error('Failed to read file data');
			}
		})
		.catch(error => {
			setLoading(false)
			alert('Kunne ikke læse fildata');
			console.error('Failed to read file data');
			console.error(error)
		});

	}

	const getHeight = () => {
		if ((deviceType === 1 || deviceType === 2) && isSmallScreen) {
			return 30
		}

		if ((deviceType === 1 || deviceType === 2) && !isWideScreen) {
			return 35
		}

		return 40
	}

	const getFontSize = () => {
		if ((deviceType === 1 || deviceType === 2) && isSmallScreen) {
			return 10
		}

		if ((deviceType === 1 || deviceType === 2) && !isWideScreen) {
			return 12
		}

		return 14
	}

	return (
		<View>
			<View style={{ flexDirection: 'row' }}>
				<Text style={modalStyle.sublabel}>Vælg Import:</Text>
				<InfoButton size={16} fontSize={12} style={{ marginLeft: 10 }} setModalVisible={setImportPlaylistGuideVisible} />
			</View>
			<View style={{ flexDirection: 'row', alignItems: 'flex-end', marginLeft: '10%', marginBottom: isWideScreen ? 10 : 0 }}>
				<View>
					<RadioButtonGroup items={importOptions} setRadioButtons={setImportOption} index={importOption} />
				</View>
				{isWideScreen && (
					<View style={{ width: '55%' }}>
						<Dropdown
							onValueChange={onChangeDestinationPlaylist}
							value={destinationPlaylist}
							items={userPlaylist}
							isDisabled={disablePlaylistDropdown}
							placeholder={'Vælg venligst'}
							width={'55%'}
							height={getHeight()}
							fontSize={getFontSize()}
						/>
					</View>
				)}
			</View>
			{!isWideScreen && (
				<View style={{ width: '60%', marginLeft: '20%', marginBottom: 20 }}>
					<Dropdown
						onValueChange={onChangeDestinationPlaylist}
						value={destinationPlaylist}
						items={userPlaylist}
						isDisabled={disablePlaylistDropdown}
						placeholder={'Vælg venligst'}
						width={'100%'}
						height={getHeight()}
						fontSize={getFontSize()}
					/>
				</View>
			)}

			<View style={{ flexDirection: 'row' }}>
				<Text style={modalStyle.sublabel} >Vælg platform:</Text>
				<InfoButton size={16} fontSize={12} style={{ marginLeft: 10 }} setModalVisible={selectedSourcePlatform === csvLabel ? setCSVPlaylistGuideVisible : setSpotifyPlaylistGuideVisible} />
			</View>
			<View style={{ flexDirection: 'row', width: '100%', marginLeft: '10%', marginBottom: 15 }}>
				{platformOptions.map((platform) => {
					return (
						<View key={platform.id + platform.value} style={{ backgroundColor: selectedSourcePlatform === platform.value ? 'white' : colors.secondary, marginTop: 5 }}>
							<Pressable
								key={platform.value}
								onPress={() => setSelectedSourcePlatform(platform.value)}
								style={{ alignItems: 'center', padding: 10 }}
							>
								{selectedSourcePlatform === platform.value ? (
									<View style={modalStyle.selectedIcon}>
										<Ionicons
											name={'checkmark-circle-outline'}
											size={30}
											color={'white'}
										/>
									</View>
								) : null}
								<Image
									source={platform.imageLink}
									style={{ width: isWideScreen ? 80 : 50, height: isWideScreen ? 80 : 50 }}
									resizeMode={'contain'}
								/>
								<Text style={{
									justifyContent: 'center',
									alignItems: 'center',
									color: selectedSourcePlatform === platform.value ? colors.accent : 'white'
								}}
								>{platform.value}</Text>
							</Pressable>
						</View>
					)
				})}
			</View>
			{selectedSourcePlatform === csvLabel ? (
				<View style={{ width: '90%', flexDirection: 'row', alignItems: 'center' }}>
					<Text style={modalStyle.sublabel}>Vælg CSV-fil til import:</Text>
					{/* <InfoButton size={16} fontSize={12} style={{ marginLeft: 5 }} setModalVisible={setCSVPlaylistGuideVisible} /> */}
					{/* 
						Downloadable template will be inside the info modal
						<View style={{flexDirection: 'row', alignItems: 'center'}}>
						<Pressable onPress={() => alert('Downloading CSV')}>
							<Text style={modalStyle.rules}>(Klik her for at downloade CSV-skabelon)</Text>
						</Pressable>
					</View> */}
				</View>
			) : (
				<View style={{ flexDirection: 'row' }}>
					<Text style={modalStyle.sublabel}>Skriv dit {selectedSourcePlatform} brugernavn: </Text>
					<InfoButton size={16} fontSize={12} style={{ marginLeft: 5 }} setModalVisible={setSpotifyUsernameGuideVisible} />
				</View>
			)}
			<View
				style={{
					flexDirection: isWideScreen ? 'row' : 'column',
					width: '100%',
					marginLeft: '10%',
					marginBottom: isWideScreen ? 5 : 0,
					marginTop: 5
				}}
			>
				{selectedSourcePlatform === csvLabel ? (
					<>
						<PrimaryButton
							title={'Vælg fil'}
							style={{ marginTop: 0, width: '80%', height: 35, justifyContent: 'center' }}
							onPress={() => pickDocument()}
						/>
					</>
				) : (
					<>
						<Input
							onChangeText={text => setUsername(text)}
							style={{ width: isWideScreen ? '60%' : '80%', backgroundColor: colors.primary, height: 35 }}
							value={username}
						/>
						<PrimaryButton
							title='Modtag Spilleliste'
							onPress={() => getPlatformPlaylists()}
							loading={loading}
							style={{
					  			width: isWideScreen ? '20%' : '80%',
								minHeight: 35,
								height: 35,
								marginLeft: isWideScreen ? 5 : 0,
								marginTop: 0
							}}
						/>
					</>
				)}
			</View>
			{selectedSourcePlatform === csvLabel && selectedFile !== '' && (
				<View style={{ flexDirection: 'row' }}>
					<Text style={modalStyle.sublabel}>Valgt Fil: {selectedFile}</Text>
					<InfoButton size={16} fontSize={12} style={{ marginLeft: 5 }} setModalVisible={setCSVPlaylistGuideVisible} />
				</View>
			)}

			{selectedSourcePlatform !== csvLabel && playlistRetrievedMessage !== '' && (
				<View style={{ flexDirection: 'row' }}>
					<Text style={modalStyle.sublabel}>{playlistRetrievedMessage}</Text>
					<InfoButton size={16} fontSize={12} style={{ marginLeft: 5 }} setModalVisible={setSpotifyUsernameGuideVisible} />
				</View>
			)}

			<View style={{
				flexDirection: isWideScreen ? 'row' : 'column',
				width: '100%',
				marginLeft: '10%',
				marginBottom: 5,
				marginTop: 5,
				alignItems: isWideScreen ? 'center' : 'flex-start'
			}}
			>
				{selectedSourcePlatform !== csvLabel && (
					<View style={{ width: isWideScreen ? '60%' : '80%', marginBottom: isWideScreen ? 0 : 15 }}>
						<Dropdown
							onValueChange={onChangeSourcePlaylist}
							value={selectedSourcePlaylist}
							items={sourcePlaylistNames}
							placeholder={'Vælg venligst'}
							isDisabled={!isPlaylistRetrieved}
							marginLeft={0}
							width={'100%'}
							height={getHeight()}
							fontSize={getFontSize()}
						/>
					</View>
				)}
				<PrimaryButton
					title='Importer Spilleliste'
					onPress={() => selectedSourcePlatform === csvLabel ? readCSVTracks() : getPlatformTracks()}
					loading={loading}
					style={{
						width: selectedSourcePlatform === csvLabel ? '80%' : (isWideScreen ? '20%' : '80%'),
						marginLeft: selectedSourcePlatform !== csvLabel && isWideScreen ? 5 : 0,
						backgroundColor: (selectedSourcePlatform !== csvLabel && isPlaylistRetrieved) ||
							(selectedSourcePlatform === csvLabel && selectedFile !== '') ?
							colors.accent : 'gray'
					}}
					isDisabled={(selectedSourcePlatform !== csvLabel && !isPlaylistRetrieved) || (selectedSourcePlatform === csvLabel && selectedFile === '')}
				/>
			</View>
			<View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', }}>
				<Text style={modalStyle.sublabel}>{tracksRetrievedMessage}</Text>
			</View>

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={undefined}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}

			{importPlaylistGuideVisible && (
				<ImportHelpModal modalVisible={importPlaylistGuideVisible} setModalVisible={setImportPlaylistGuideVisible} info={importPlaylist} />
			)}
			
			{spotifyPlaylistGuideVisible && (
				<ImportHelpModal modalVisible={spotifyPlaylistGuideVisible} setModalVisible={setSpotifyPlaylistGuideVisible} info={spotifyPlaylist} />
			)}
			
			{spotifyUsernameGuideVisible && (
				<ImportHelpModal modalVisible={spotifyUsernameGuideVisible} setModalVisible={setSpotifyUsernameGuideVisible} info={spotifyUsername} />
			)}
			
			{csvPlaylistGuideVisible && (
				<ImportHelpModal modalVisible={csvPlaylistGuideVisible} setModalVisible={setCSVPlaylistGuideVisible} info={csvPlaylist} />
			)}
		</View>
	)
}
