import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal, Platform, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { CHANGEPASS_URL, colors, CREATEUSER_URL, USERLIST_URL } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'
import { PrimaryButton, SecondaryButton } from '../Buttons/Button'
import { DateTimePickerInput } from '../Forms/DateTimePicker'
import { Input } from '../Forms/Input'
import RadioButtonGroup from '../Forms/RadioButtonGroup'
import { Select } from '../Forms/Select'
import { DateTimePickerWeb } from '../Forms/DateTimePickerWeb'
import { he } from 'react-native-paper-dates'

export const UserEditModal = ({
	modalVisible,
	handleClose,
	modalType = 'add',
	editUserProps = undefined,
	getUserList,
	openNotifModal,
	closeNotifModal
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '40%'
		} else {
			return '80%'
		}
	}

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			width: getModalWidth(),
			height: isWideScreen ? '80%' : '70%',
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: isWideScreen ? 20 : 10,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5
		},
		sublabel: {
			color: 'white',
			fontSize: isWideScreen ? 20 : (isSmallScreen ? 14 : 16),
			marginLeft: '5%'
		},
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : (isSmallScreen ? 14 : 16),
			marginLeft: '5%'
		},
		errorMessage: {
			color: 'red',
			marginLeft: '5%',
			marginBottom: 10
		}
	})

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [expiryDate, setExpiryDate] = useState('')
	// const [expiryDate, setexpiryDate] = useState(new Date().toISOString().slice(0, 10))
	const [userType, setUserType] = useState(3)
	const [statusValue, setStatusValue] = useState(0)
	const [stateErrorMessage, setStateErrorMessage] = useState({
		password: '',
		username: '',
		expiry: ''
	})
	const { getTokens } = useTokens()

	const userTypes = [
		{
			value: 1,
			label: 'Tidsbegrænset Login'
		},
		{
			value: 3,
			label: 'Almindelig Bruger'
		},
		{
			value: 4,
			label: 'Super Bruger'
		}
	]

	const status = [
		{
			id: '1',
			label: 'Aktiv',
			value: 'true'
		},
		{
			id: '2',
			label: 'Inaktiv',
			value: 'false'
		}
	]

	useEffect(() => {
		if (modalType === 'edit') {
			setUserType(editUserProps.user_type)
			setExpiryDate(
				editUserProps.expiration_date === null || editUserProps.expiration_date === undefined
					? ''
					: editUserProps.expiration_date
			)
			// setExpiryDate(
			// 	editUserProps.expiration_date === null || editUserProps.expiration_date === undefined
			// 		? new Date()
			// 		: new Date(editUserProps.expiration_date)
			// )
			setUsername(editUserProps.username)
			setStatusValue(editUserProps.is_active === true ? 0 : 1)
		}
	}, [])

	const validateUsername = errorMessage => {
		if (username === '') {
			errorMessage.username = 'Brugernavn er påkrævet'
		} else {
			errorMessage.username = ''
		}
		return errorMessage
	}

	const validateExpiry = errorMessage => {
		if (userType === 1) {
			if (expiryDate === undefined || expiryDate === '') {
				errorMessage.expiry = 'Udløb er påkrævet'
			}
		}
		return errorMessage
	}

	const validatePassword = errorMessage => {
		if (password !== '' && password !== undefined) {
			if (password.length < 8) errorMessage.password = 'Adgangskoden skal være mindst 8 tegn'
		} else {
			errorMessage.password = 'Adgangskode er påkrævet'
		}
		return errorMessage
	}

	const changePassword = async () => {
		const { access } = await getTokens()
		axios
			.put(
				CHANGEPASS_URL + editUserProps.id + '/',
				{
					password
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					console.log('SUCCESSFULLY CHANGED PASSWORD')
				} else {
					console.log('FAILED TO CHANGE PASSWORD: ' + JSON.stringify(response))
				}
			})
	}

	const createUser = async () => {
		let user_type = userType
		let is_active = true
		let expiration_date = expiryDate === '' ? null : expiryDate
		const { access } = await getTokens()
		axios
			.post(
				CREATEUSER_URL,
				{
					username,
					password,
					user_type,
					is_active,
					expiration_date
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					getUserList()
					openNotifModal('Tilføj bruger', 'Tilføjet')
				} else {
					openNotifModal('Tilføj bruger', 'Kunne ikke tilføje')
				}
			})
			.catch(error => {
				alert("Brugernavn eksisterer allerede")
			})
	}

	const updateUser = async () => {
		let user_type = userType
		let is_active = statusValue === 0 ? true : false
		let expiration_date = expiryDate === '' ? null : expiryDate
		const { access } = await getTokens()
		axios
			.put(
				USERLIST_URL + editUserProps.id + '/',
				{
					username,
					password,
					user_type,
					is_active,
					expiration_date
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					getUserList()
					openNotifModal('Opdater bruger', 'Opdateret')
				} else {
					openNotifModal('Opdater bruger', 'Kunne ikke opdatere')
				}
			})
	}

	const handleOk = () => {
		let errorMessage = {
			username: '',
			password: '',
			expiry: ''
		}
		errorMessage = validateUsername(errorMessage)
		if (modalType === 'add') {
			errorMessage = validatePassword(errorMessage)
		}
		errorMessage = validateExpiry(errorMessage)
		if (errorMessage.password === '' && errorMessage.username === '' && errorMessage.expiry === '') {
			if (modalType === 'edit') {
				updateUser()
				if (password !== '' && password !== undefined) {
					if (changePassword() === false) return
					else {
						handleClose()
					}
				} else {
					handleClose()
				}
			} else {
				createUser()
				handleClose()
			}
		} else {
			setStateErrorMessage(errorMessage)
			return
		}
	}

	const clearExpiryErroMsg = () => {
		let errorMessage = stateErrorMessage
		errorMessage.expiry = ''
		setStateErrorMessage(errorMessage)
	}

	const onChangeDateTimePicker = selectedDate => {
		// setExpiryDate(selectedDate)
		setExpiryDate(selectedDate)
		clearExpiryErroMsg()
	}

	const onChangeSelectUserType = selected => {
		setUserType(selected)
		if (userType !== 1) setExpiryDate('')
		else {
			// setExpiryDate(new Date())
			setExpiryDate(new Date().toISOString().slice(0, 10))
		}

		clearExpiryErroMsg()
	}

	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					handleClose()
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<Text style={[trackStyles.sublabel, { marginVertical: '5%' }]}>{modalType === 'add' ? 'Tilføj bruger' : 'Rediger bruger'}</Text>
						<View style={{
							marginLeft: '5%',
							width: '90%',
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: isWideScreen ? 10 : 5
						}}
						>
							<View style={{ width: isWideScreen ? '30%' : '40%' }}>
								<Text style={trackStyles.label}>Brugernavn</Text>
							</View>
							<View style={{ width: isWideScreen ? '70%' : '60%' }}>
								<Input
									style={{ width: '100%', marginBottom: 0 }}
									onChangeText={text => {
										setUsername(text)
										if (text !== '') {
											let errorMessage = stateErrorMessage
											errorMessage.username = ''
											setStateErrorMessage(errorMessage)
										}
									}}
									placeholder={'Brugernavn'}
									value={username}
								/>
							</View>
						</View>
						{stateErrorMessage.username !== '' && (
							<Text style={trackStyles.errorMessage}>{stateErrorMessage.username}</Text>
						)}
						<View style={{
							marginLeft: '5%',
							width: '90%',
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: isWideScreen ? 10 : 5
						}}
						>
							<View style={{ width: isWideScreen ? '30%' : '40%' }}>
								<Text style={trackStyles.label}>Adgangskode</Text>
							</View>
							<View style={{ width: isWideScreen ? '70%' : '60%' }}>
								<Input
									style={{ width: '100%', marginBottom: 0 }}
									onChangeText={text => {
										setPassword(text)
										if (text !== '') {
											let errorMessage = stateErrorMessage
											errorMessage.password = ''
											setStateErrorMessage(errorMessage)
										}
									}}
									placeholder={'Adgangskode'}
									value={password}
									type="password"
								/>
							</View>
						</View>
						{stateErrorMessage.password !== '' && (
							<Text style={trackStyles.errorMessage}>{stateErrorMessage.password}</Text>
						)}
						<View style={{
							marginLeft: '5%',
							width: '90%',
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: isWideScreen ? 10 : 5
						}}
						>
							<View style={{ width: isWideScreen ? '30%' : '40%' }}>
								<Text style={trackStyles.label}>Brugertype</Text>
							</View>
							<View style={{ width: isWideScreen ? '70%' : '60%' }}>
								<Select
									onValueChange={onChangeSelectUserType}
									value={userType}
									items={userTypes}
									width={'100%'}
									customMarginLeft={0}
									customInput2Width={isWideScreen ? '30%' : '50%'}
								/>
							</View>
						</View>
						<View style={{
							marginLeft: '5%',
							width: '90%',
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: isWideScreen ? 10 : 5
						}}
						>
							<View style={{ width: isWideScreen ? '30%' : '40%' }}>
								<Text style={trackStyles.label}>Udløbsdato</Text>
							</View>
							{Platform.OS === 'web' ? (
								<View style={{ width: isWideScreen ? '70%' : '60%' }}>
									<DateTimePickerWeb
										onChange={onChangeDateTimePicker}
										value={expiryDate}
										placeholder={'Expiry Date'}
										style={{
											backgroundColor: colors.secondaryInputColor,
											width: '100%'
										}}
										customMarginTop={0}
									/>
								</View>
							) : (
								<View style={{ width: isWideScreen ? '70%' : '60%' }}>
									<DateTimePickerInput
										onChange={onChangeDateTimePicker}
										value={expiryDate}
										placeholder={'Expiry Date'}
										style={{
											backgroundColor: colors.secondaryInputColor,
											width: '100%'
										}}
										customWidth={'100%'}
										customMarginLeft={0}
										customPaddingLeft={0}
									/>
								</View>
							)}
						</View>
						{stateErrorMessage.expiry !== '' && (
							<Text style={trackStyles.errorMessage}>{stateErrorMessage.expiry}</Text>
						)}
						{modalType !== 'add' && (
							<View style={{
								marginLeft: '5%',
								width: '90%',
								flexDirection: 'row',
								alignItems: 'flex-start',
								marginBottom: isWideScreen ? 10 : 5
							}}
							>
								<View style={{ width: isWideScreen ? '30%' : '40%' }}>
									<Text style={trackStyles.label}>Status</Text>
								</View>
								<View style={{ width: isWideScreen ? '70%' : '60%' }}>
									<RadioButtonGroup items={status} setRadioButtons={setStatusValue} index={statusValue} isSingleLineRender={isWideScreen} />
								</View>
							</View>
						)}
						<View style={{
							marginLeft: '5%',
							width: '90%',
							flexDirection: 'row',
							justifyContent: 'space-evenly',
							alignItems: 'center',
							marginVertical: isWideScreen ? 10 : 5
						}}
						>
							<SecondaryButton title={'Anuller'} onPress={() => handleClose()}
								style={{
									width: isWideScreen ? 180 : (isSmallScreen ? 80 : 120),
									minHeight: isWideScreen ? 35 : 30,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							/>
							<PrimaryButton title={'Gem'} onPress={() => handleOk()}
								style={{
									width: isWideScreen ? 180 : (isSmallScreen ? 80 : 120),
									height: isWideScreen ? 35 : 30
								}}
							/>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
