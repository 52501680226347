import { Ionicons } from '@expo/vector-icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ScrollView, Text, View, useWindowDimensions } from 'react-native'
import { Menu, MenuItem } from 'react-native-material-menu'
import { PLAYLIST_TRACKS_URL } from '../../../Helpers/variables'
import useTokens from '../../../Hooks/useTokens'
import { CreatePlaylistModal } from './CreatePlaylistModal'
import { trackStyles } from './TrackStyles'

export const PlaylistTrackMenu = ({
	track,
	setMenuVisible,
	userPlaylists,
	updateUserPlaylists,
	closeNotifModal,
	openNotifModal,
	position = 0
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	useEffect(() => {
		handleResize()
	}, [width])

	const [playlistVisible, setPlaylistVisible] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const { getTokens } = useTokens()

	const addToPlaylist = async (playlistId, track) => {
		const { access } = await getTokens()
		axios
			.post(
				PLAYLIST_TRACKS_URL + playlistId,
				{ track, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					openNotifModal('Tilføj til spilleliste', 'Tilføjet', true)
				} else {
					openNotifModal('Tilføj til spilleliste', 'Kunne ikke tilføje', true)
				}
			})
			.catch(err => {
				console.log('addToPlaylist: ' + err)
				if (err.message === 'Request failed with status code 400') {
					openNotifModal('Tilføj til spilleliste', 'Nummeret findes allerede i spilleliste', true)
				}
			})
	}

	const handleModalClosing = (value) => {
		setModalVisible(value)
		setPlaylistVisible(value)
		setMenuVisible(value)
	}

	const handlePlaylistMenuClosing = () => {
		if (!modalVisible) {
			setPlaylistVisible(false)
		}
	}

	return (
		<View key={'playlistviewkey' + track.id}>
			<Menu
				style={trackStyles.menuView}
				key={'playlistmenu' + track.id}
				visible={playlistVisible}
				anchor={
					<MenuItem
						onPress={() => {
							setPlaylistVisible(true)
						}}
					>
						<Text style={trackStyles.menuTextView}>Tilføj til spilleliste</Text>
					</MenuItem>
				}
				onRequestClose={() => handlePlaylistMenuClosing() }
			>
				<View>
					<Ionicons name="arrow-back" size={25} color={'white'} style={{ marginLeft: 15 }} />
				</View>
				<MenuItem
					key={'playlistkey00'}
					onPress={() => {
						setModalVisible(true)
					}}
					onRequestClose={() => handlePlaylistMenuClosing() }
				>
					<Text style={[trackStyles.menuTextView, { margin: 15, fontSize: 15, fontWeight: 'bold' }]}>
						Opret ny spilleliste
					</Text>
				</MenuItem>
				<ScrollView style={{ maxHeight: 200 }}>
					{userPlaylists.map(playlist => (
						<MenuItem
							key={'playlistkey' + playlist.id}
							onPress={() => {
								addToPlaylist(playlist.id, track.id)
								setPlaylistVisible(false)
								setMenuVisible(false)
							}}
						>
							<Text style={trackStyles.menuTextView}>{playlist.name}</Text>
						</MenuItem>
					))}
				</ScrollView>
					{modalVisible && (
						<CreatePlaylistModal
							modalVisible={modalVisible}
							setModalVisible={handleModalClosing}
							updateUserPlaylists={updateUserPlaylists}
							addToPlaylist={addToPlaylist}
							trackId={track.id}
						/>
					)}
			</Menu>
		</View>
	)
}
