import { Modal, View, useWindowDimensions, StyleSheet } from 'react-native'
import CreatePlaylistScreen from '../../../Screens/CreatePlaylistScreen/CreatePlaylistScreen'
import { useState, useEffect } from 'react'
import { colors } from '../../../Helpers/variables'

export const CreatePlaylistModal = ({
	modalVisible,
	setModalVisible,
	playlistInfo,
	processType = 'add',
	updateUserPlaylists = undefined,
	addToPlaylist = undefined,
	trackId,
	albumDetails = undefined
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '40%'
		} else {
			return '80%'
		}
	}

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: isWideScreen ? 20 : 10,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: isWideScreen ? 35 : 20,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			justifyContent: 'center',
			width: getModalWidth(),
			height: '80%'
		}
	})
	return (
		// <View style={trackStyles.centeredView}>
		<Modal animationType="slide" transparent={true} visible={modalVisible}>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<CreatePlaylistScreen
						setModalVisible={setModalVisible}
						playlistInfo={playlistInfo}
						processType={processType}
						updateUserPlaylists={updateUserPlaylists}
						addToPlaylist={addToPlaylist}
						trackId={trackId}
						albumDetails={albumDetails}
					/>
				</View>
			</View>
		</Modal>
		// </View>
	)
}
