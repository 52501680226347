import { useEffect, useState } from 'react'
import { Alert, Modal, Platform, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import * as Sentry from 'sentry-expo'
import { captureException } from '../../Helpers/sentryLog'
import {
	ASSIGNED_URL,
	COPYPLAYLIST_ASSIGNED_URL,
	INTERNAL_URL,
	REVERSEUSERLOOKUP_URL,
	colors
} from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import { PrimaryButton, SecondaryButton } from '../Buttons/Button'
import { CheckListbox } from '../Forms/CheckListbox'
import { Listbox } from '../Forms/Listbox'

export const AssignPlaylistToUserModal = ({ isModalOpen, setModalOpen, username, userid }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	const [selectedItems, setSelectedItems] = useState([])
	const [originalSelectectedItems, setOriginalSelectedItems] = useState([])
	const [removedItems, setRemovedItems] = useState([])
	const [playlists, setPlaylist] = useState([])
	const [isSelectAllChecked, setSelectAllChecked] = useState(false)
	const api = useAxios()

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			width: isWideScreen ? '40%' : '80%',
			height: isWideScreen ? '80%' : '80%',
			margin: isWideScreen ? 15 : 10,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5
		},
		label: {
			marginBottom: 10,
			color: 'white',
			fontSize: isWideScreen ? 20 : 18,
			marginLeft: '3%',
			marginTop: '5%'
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})

	const getPlaylist = async () => {
		await api
			.get(INTERNAL_URL)
			.then(({ data }) => {
				data = data.sort(function (a, b) {
					return a.id - b.id
				})
				getCopyPlaylist(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
	}

	const getCopyPlaylist = async playlists => {
		await api
			.get(COPYPLAYLIST_ASSIGNED_URL)
			.then(({ data }) => {
				// data = data.sort(function (a, b) {
				// 	return a.id - b.id
				// })
				data.map(item => {
					if (playlists.filter(x => x.id === item.playlist).length === 0 && item.playlist_name)
						playlists.push({
							id: item.playlist,
							name: item.playlist_name
						})
				})
				playlists = playlists.sort(function (a, b) {
					return a.name - b.name
				})
				getUserAssignedPlaylist(playlists)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
	}

	const getUserAssignedPlaylist = async listboxItemsTemp => {
		await api
			.get(REVERSEUSERLOOKUP_URL + userid)
			.then(({ data }) => {
				data = data.sort(function (a, b) {
					return a.playlist - b.playlist
				})
				let newPlaylists = listboxItemsTemp

				newPlaylists = newPlaylists.map(item => {
					item.isChecked = false
					return item
				})

				data.map(item => {
					let itemTemp = newPlaylists.filter(x => x.id === item.playlist)[0]
					newPlaylists = newPlaylists.filter(x => x.id !== item.playlist)
					if (itemTemp) {
						itemTemp.isChecked = true
						newPlaylists.push(itemTemp)
					}
				})
				newPlaylists = newPlaylists.sort(function (a, b) {
					return a.name - b.name
				})
				setPlaylist(newPlaylists)
				setSelectedItems(data)
				setOriginalSelectedItems(data)

			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
	}

	const removeUserPlaylist = () => {
		let totalCount = 0
		removedItems.map(item => {
			api
				.delete(ASSIGNED_URL + item.id)
				.then(response => {
					if (response.status === 204) {
						if (selectedItems.length === 0) totalCount++
						if (totalCount === removedItems.length && selectedItems.length === 0) {
							Alert.alert('Fjernet spillelisten', 'Spillelisten er blevet fjernet til brugeren', [
								{ text: 'OK', onPress: () => console.log('OK Pressed') }
							])

							setModalOpen(false)
						}
					} else {
						console.log('remove playlist fail: ' + item.id)
					}
				})
				.catch(err => {
					console.log(err)
				})
		})
	}

	const addUserPlaylist = () => {
		let totalCount = 0
		selectedItems.map(item => {
			handleAddUser(item)
			totalCount = totalCount + 1
			if (totalCount === selectedItems.length) {
				Alert.alert('Opret spillelisten', 'Spillelisten er blevet tildelt til brugeren', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])

				setModalOpen(false)
			}
			return true
		})
	}

	const handleAddUser = async item => {
		let is_allowed_to_be_removed = false
		let user = userid
		let playlist = item.playlist ? item.playlist : item.id
		api
			.post(ASSIGNED_URL, {
				is_allowed_to_be_removed,
				user,
				playlist
			})
			.then(response => {
				if (response.status === 201) {
					console.log('success: ' + item.name)
				} else {
					console.log('failed: ' + item.name)
				}
			})
			.catch(err => {
				captureException(err)
			})
	}

	useEffect(() => {
		getPlaylist()
	}, [userid, username])

	// useEffect(() => {
	// 	getUserAssignedPlaylist()
	// }, [isPlaylistLoaded])

	const refreshListboxSelectedItems = (item, isChecked) => {
		let selectedItemsTemp = selectedItems
		if (isChecked) {
			let selectedItem = selectedItemsTemp.filter(x => (x.playlist ? x.playlist : x.id) === item.id)
			if (selectedItem.length === 0) selectedItemsTemp.push(item)

			selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
				return a.id - b.id
			})
			setSelectedItems(selectedItemsTemp)
		} else {
			selectedItemsTemp = selectedItemsTemp.filter(x => (x.playlist ? x.playlist : x.id) !== item.id)
			setSelectedItems(selectedItemsTemp)

			let removedItem = originalSelectectedItems.filter(x => (x.playlist ? x.playlist : x.id) === item.id)[0]
			let removedItemsTemp = removedItems
			removedItemsTemp.push(removedItem)
			setRemovedItems(removedItemsTemp)
		}
	}

	const handleRemoveAll = () => {
		let listboxItemsTemp = playlists
		listboxItemsTemp = listboxItemsTemp.filter(x => (x.isChecked = false))
		setSelectedItems([])
		let removedItemsTemp = []
		originalSelectectedItems.map(item => {
			let removedItem = originalSelectectedItems.filter(
				x => (x.playlist ? x.playlist : x.id) === (item.playlist ? item.playlist : item.id)
			)[0]
			removedItemsTemp.push(removedItem)
		})
		setRemovedItems(removedItemsTemp)
		setSelectAllChecked(false)
	}

	const handleOk = () => {
		if (selectedItems.length > 0) addUserPlaylist()

		if (removedItems.length > 0) removeUserPlaylist()
	}

	return (
		<View style={styles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={isModalOpen}
				onRequestClose={() => {
					handleClose()
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={{ flexDirection: 'row' }}>
							<Text style={[styles.label]}>{'Tildel spilleliste til ' + username}</Text>
						</View>
						<View style={{ height: '75%', flexDirection: 'row', justifyContent: 'center' }}>
							<CheckListbox
								listboxItems={playlists}
								setListboxItems={setPlaylist}
								hasSelectAll={true}
								hasListboxSelectedItems={true}
								refreshListboxSelectedItems={refreshListboxSelectedItems}
								title={'Vælg spillelister'}
								handleRemoveAll={handleRemoveAll}
								setSelectAllChecked={setSelectAllChecked}
								isSelectAllChecked={isSelectAllChecked}
								emptyLabel="Ingen spillelister"
							/>
							<Listbox
								items={selectedItems}
								hasRemoveAll={true}
								title={'Udvalgte spillelister'}
								handleRemoveAll={handleRemoveAll}
								emptyLabel="Ingen spillelister"
							/>
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
							<SecondaryButton
								title={'Anuller'}
								onPress={() => setModalOpen(false)}
								style={{
									width: isWideScreen ? 180 : (isSmallScreen ? 90 : 120),
									minHeight: isWideScreen ? 35 : 35,
									margin: isWideScreen ? 10 : 5
								}}
							/>
							<PrimaryButton
								title={'Gem'}
								onPress={() => handleOk()}
								style={{
									width: isWideScreen ? 180 : (isSmallScreen ? 90 : 120),
									minHeight: isWideScreen ? 35 : 30,
									margin: isWideScreen ? 10 : 5
								}}
							/>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
