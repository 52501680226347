import { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions } from 'react-native'
import truncateStr from '../../Helpers/truncateStr'
import { PrimaryButton } from '../Buttons/Button'
import { userStyles } from './UserStyles'

const User = ({ user, handleEditUser, buttonTitle = 'Rediger', ...props }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const userTypes = [
		{
			usertype: 1,
			usertypedesc: 'Tidsbegrænset Login'
		},
		{
			usertype: 3,
			usertypedesc: 'Almindelig Bruger'
		},
		{
			usertype: 4,
			usertypedesc: 'Super Bruger'
		},
		{
			usertype: 5,
			usertypedesc: 'Super Bruger'
		}
	]

	return (
		<View style={userStyles.userRow} {...props}>
			<View style={[userStyles.rowContainer, {width: isWideScreen ? '30%' : '30%'}]}>
				<Text style={userStyles.albumTitle}>{truncateStr(user.username, 45)}</Text>
			</View>
			<View style={[userStyles.flex1Container, {width: isWideScreen ? '20%' : '30%'}]}>
				<Text style={userStyles.albumTitle}>{truncateStr(user.expiration_date, 45)}</Text>
			</View>
			<View style={[userStyles.rowContainer, {width: isWideScreen ? '25%' : '20%'}]}>
				<Text style={userStyles.albumTitle}>
					{truncateStr(userTypes.filter(x => x.usertype === user.user_type)[0] ? userTypes.filter(x => x.usertype === user.user_type)[0].usertypedesc : '', 45)}
				</Text>
			</View>
			<View style={[userStyles.buttonContainer, {paddingRight: 5, width: isWideScreen ? '25%' : (isSmallScreen ? '30%' : '25%') }]}>
				<PrimaryButton title={buttonTitle} onPress={() => handleEditUser(user)}
					style={{ width: isWideScreen ? 120 : 70, minHeight: isWideScreen ? 35 : (buttonTitle === 'Rediger' ? 35 : 40) }} />
			</View>
		</View>
	)
}

export default User
