import { useEffect, useState } from 'react'
import { Modal, StyleSheet, Text, View, FlatList, ScrollView, useWindowDimensions, Pressable } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../Buttons/Button'
import { colors, CREATE_PLAYLIST_URL, BULK_SONG_REQUEST, SONGREQUEST } from '../../Helpers/variables'
import { Input, TextArea } from '../Forms/Input'
import { CheckboxTicker } from '../Forms/CheckboxTicker'
import isEmailValid from '../../Helpers/validateFields'
import axios from 'axios'
import useTokens from '../../Hooks/useTokens'
import useAxios from '../../Hooks/useAxios'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import Checkbox from 'expo-checkbox'

export const ImportPurchaseTracksModal = ({
	importObject,
	nonExistingTracks,
	handleRequestInfo
}) => {

	const spotifyLabel = 'Spotify'
	const youtubeLabel = 'Youtube'
	const csvLabel = 'CSV File'
	const appleLabel = 'Apple Music'

	const api = useAxios()
	const { getTokens } = useTokens()
	const { width } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(false)
	const [updatedNonExistingTracks, setUpdatedNonExistingTracks] = useState(nonExistingTracks)
	const [emailAddress, setEmailAddress] = useState('')
	const [emailMessage, setEmailMessage] = useState('')
	const [requestCompletedMessage, setRequestCompletedMessage] = useState('')
	const [countRequested, setCountRequested] = useState(0)
	const [countFailed, setCountFailed] = useState(0)
	const [selectedTracks, setSelectedTracks] = useState(nonExistingTracks)
	const [isSelectAllChecked, setIsSelecctAllChecked] = useState(false)

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const handleResize = () => {
		setIsWideScreen(width > 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	useEffect(() => {
		console.log('importobj new', importObject)
		checkSelectedTracks()
	}, [updatedNonExistingTracks])

	const modalStyle = StyleSheet.create({
		centeredView: {
			flex: 1,
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: colors.secondary,
			borderRadius: 20,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			width: isWideScreen ? '50%' : '30%',
			height: isWideScreen ? '90%' : '40%',
		},
		label: {
			color: 'white',
			fontSize: isWideScreen ? 20 : 18,
			marginLeft: isWideScreen ? 20 : 0
		},
		sublabel: {
			color: 'white',
			fontSize: isWideScreen ? 16 : 14,
			marginTop: 5,
		},
		innerLabel: {
			fontStyle: 'italic',
			color: 'white',
			fontSize: isWideScreen ? 12 : 10,
			marginLeft: '10%',
			marginTop: 10,
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 14,
			marginLeft: '10%',
		},
		selectedIcon: {
			position: 'absolute',
			width: 30,
			height: 30,
			zIndex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 30,
			backgroundColor: colors.accent,
			borderRadius: 60
		},
		trackListScrollView: {
			flexGrow: 1,
			borderColor: 'gray',
			borderRadius: 5,
			borderWidth: 2,
			width: '80%',
			minHeight: '30%',
			marginLeft: '10%',
		}
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (description) => {
		setNotifModal({
			visible: true,
			title: '',
			description: description,
			isDisappearing: false
		})
	}

	const handlePurchaseTracks = async () => {
		setLoading(true)

		if (emailAddress.trim() === '') {
			setRequestCompletedMessage('Venligst oplys en e-mailadresse.')
			handleRequestInfo(true)
			setLoading(false)
			return
		}

		if (!isEmailValid(emailAddress.trim())) {
			setRequestCompletedMessage('Ugyldig e-mailadresse. Prøv venligst igen.')
			handleRequestInfo(true)
			setLoading(false)
			return
		}

		//Comment out to make Message optionl
		// if (emailMessage.trim() === '') {
		// 	setRequestCompletedMessage('Venligst angiv en anmodningsmeddelelse.')
		// 	handleRequestInfo(true)
		// 	setLoading(false)
		// 	return
		// }

		handleRequestInfo(false)

		if (importObject.importOption === 0) {
			await createNewPlaylist()
		}
		else {
			await createBulkRequest()
		}

		let result = ''
		if (countRequested > 0) {
			result = countRequested + ' anmodninger sendt. '
		}

		if (countFailed > 0) {
			result += countRequested + ' anmodninger mislykkedes. Prøv venligst igen, eller klik på Send for at afslutte.'
		} else {
			result += 'Klik på Send for at afslutte.'
		}

		setRequestCompletedMessage(result)
	}

	const checkSelectedTracks = () => {
		let tempExisting = [...updatedNonExistingTracks]
		const selectedTracks = tempExisting.filter(x => x.isChecked)
		setSelectedTracks(selectedTracks)
	}

	const createNewPlaylist = async () => {
		const playlistName = importObject.playlistName

		const formData = new FormData()
		formData.append('name', playlistName)
		formData.append('description', playlistName)
		formData.append('visibility', '1')

		const { access } = await getTokens()
		const { data } = axios
			.post(CREATE_PLAYLIST_URL, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					// let newImportObject = updatedImportObject
					// newImportObject.destinationPlaylist = response.data.id
					// setUpdatedImportObject(newImportObject)
					createBulkRequest(response.data.id)
				}
			})
			.catch(err => {
				alert('Noget gik galt')
				console.log('createNewPlaylist: ' + err)
				setLoading(false)
			})
			.finally(() => {
			})

		return data
	}

	const getSourcePlatformid = (sourcePlatform) => {
		let result = 0
		switch (sourcePlatform) {
			case spotifyLabel:
				result = 0
				break
			case youtubeLabel:
				result = 1
				break
			case appleLabel:
				result = 2
				break
			case csvLabel:
				result = 3
				break
		}

		return result
	}

	const createBulkRequest = async (playlistId = importObject.destinationPlaylist) => {
		const formData = new FormData()
		formData.append('playlist', playlistId)
		formData.append('total_tracks', selectedTracks.length)
		formData.append('source_external_app', getSourcePlatformid(importObject.sourcePlatform))

		const { access } = await getTokens()
		const { data } = axios
			.post(BULK_SONG_REQUEST, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(async response => {
				await requestTrack(response)
				openNotifModal('Sange anmodet med succes. Klik på Afslut for at fuldføre processen.')
			})
			.catch(err => {
				alert('Noget gik galt')
				console.log('createBulkRequest: ' + err)
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
			})

		return data
	}

	const requestTrack = async (bulkResponse) => {
		const bulkId = bulkResponse.data.id
		let sucessCount = 0
		selectedTracks.map(async item => {
			try {
				let artist = item.artists
				let album = item.album
				let track = item.title
				let message = emailMessage
				let email = emailAddress
				let bulk = bulkId
				let { status: status } = await api.post(SONGREQUEST, {
					artist,
					album,
					track,
					message,
					email,
					bulk
				})

				if (status === 201) {
					let count = countRequested
					setCountRequested(count++)
					sucessCount++

					if (Platform.OS === 'web') console.log('Email sendt!')
					else console.log('Anmod om en sang', 'Email sendt!', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					let count = countFailed
					setCountFailed(count++)
					if (Platform.OS === 'web') console.log('Kunne ikke sende e-mail!')
					else
						console.log('Anmod om en sang', 'Kunne ikke sende e-mail!', [
							{ text: 'OK', onPress: () => console.log('OK Pressed') }
						])
				}
				// if(sucessCount === selectedTracks.length) {
				// 	alert('Email sendt!')
				// }
			} catch (err) {
				let count = countFailed
				setCountFailed(count++)
				console.log(err)
				console.log('Anmod om en sang', 'Kunne ikke sende e-mail!', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
			}
		})
	}

	const onChangeCheckedStatusNonExisting = (objItem) => {
		let tempNonExisting = [...updatedNonExistingTracks]
		let itemIndex = tempNonExisting.findIndex(x => x.id === objItem.id)
		let item = tempNonExisting[itemIndex]
		item.isChecked = !objItem.isChecked
		tempNonExisting[itemIndex] = item
		setUpdatedNonExistingTracks(tempNonExisting)

		let selectAllCheck = tempNonExisting.filter(x => x.isChecked === true).length === nonExistingTracks.length
		setIsSelecctAllChecked(selectAllCheck)
	}

	const onChangeCheckedStatusNonExistingSelectUnselectAll = (isChecked) => {
		let tempNonExisting = [...nonExistingTracks]
		tempNonExisting = tempNonExisting.filter(x => (x.isChecked = isChecked))
		setUpdatedNonExistingTracks(tempNonExisting)
		setIsSelecctAllChecked(isChecked)
	}

	const TracksChecklist = ({ item, isChecked, handleCheckboxClick }) => {
		return (
			<CheckboxTicker
				isChecked={isChecked}
				label={item.title + ' by ' + item.artists}
				handleCheckboxClick={handleCheckboxClick}
				item={item}
				style={{ fontSize: 15 }}
			/>
		)
	}

	return (
		<View>
			<View style={{ flexDirection: 'row', alignItems: 'center', width: '80%', marginLeft: '10%', marginBottom: 10 }}>
			{updatedNonExistingTracks.length > 0 ? (
				<Text style={modalStyle.sublabel}>Her ser du de {updatedNonExistingTracks.length} musiknumre vi ikke har i vores database. 
					Vælg de numre du ønsker vi skal hente ind i vores database. 
					Du kan bruge ”Vælg alle” funktionen hvis det ønskes. 
					Skriv din e-mail og eventuelt en besked. 
					Klik på knappen ”Forespørg på sange for at sende forespørgslen. 
					Under menuen ”mine spillelister” vil du oppe i menuen ”Se forespurgte sange” kunne se status på din forespørgsel.
				</Text>
			) : (
				<Text style={modalStyle.sublabel}>Ingen Ikke-eksisterende numre er hentet. Klik på Send for at fuldføre processen.</Text>
			)}
			</View>
			<ScrollView style={modalStyle.trackListScrollView}>
				<Pressable
					onPress={() => {
						onChangeCheckedStatusNonExistingSelectUnselectAll(!isSelectAllChecked)
					}}
				>
					<View style={{
						marginTop: 5,
						marginBottom: 5,
						marginLeft: -1,
						flexDirection: 'row',
						alignItems: 'center'
					}}>
						<Checkbox
							style={{ margin: 10 }}
							value={isSelectAllChecked}
							color={isSelectAllChecked ? colors.accent : undefined}
						/>
						<Text style={{
							fontSize: 15,
							color: 'white'
						}}>{isSelectAllChecked ? 'Fravælg alle' : 'Vælg alle'}</Text>
					</View>
				</Pressable>
				<View style={{ flexDirection: 'row', width: '100%', marginBottom: 15, marginTop: 5, alignItems: 'center' }}>
					<View>
						<FlatList
							data={nonExistingTracks}
							renderItem={track =>
								<View style={{ marginRight: isWideScreen ? 30 : 0 }} >
									<TracksChecklist item={track.item} isChecked={false} handleCheckboxClick={onChangeCheckedStatusNonExisting} />
								</View>
							}
							ListEmptyComponent={
								<Text style={{ color: 'white' }}>Ingen data...</Text>
							}
							keyExtractor={track => track.id}
						/>
					</View>
				</View>
			</ScrollView>
			<Text style={[modalStyle.sublabel, {marginLeft: '10%'}]}>Skriv din Email Adresse: </Text>
			<View style={{ flexDirection: 'row', width: '100%', marginLeft: '10%', marginTop: 5 }}>
				<Input
					onChangeText={text => setEmailAddress(text)}
					style={{ width: isWideScreen ? '80%' : '80%', backgroundColor: colors.primary, height: 35 }}
					value={emailAddress}
				/>
			</View>
			<Text style={[modalStyle.sublabel, {marginLeft: '10%'}]}>Anmodningsbesked (valgfri): </Text>
			<View style={{ flexDirection: 'row', width: isWideScreen ? '100%' : '80%', marginLeft: '10%' }}>
				<TextArea
					onChangeText={text => setEmailMessage(text)}
					style={{ width: isWideScreen ? '80%' : '100%', backgroundColor: colors.primary, height: 80 }}
					value={emailMessage}
					numberOfLine={3}
					maxLength={500}
				/>
			</View>
			<View style={{ flexDirection: 'row', width: '100%', marginLeft: '10%', alignItems: 'center', }}>
				<PrimaryButton
					title='Anmod om køb af numre'
					onPress={() => handlePurchaseTracks()}
					loading={loading}
					style={{
						width: isWideScreen ? '80%' : '80%',
						marginRight: 2,
						backgroundColor: updatedNonExistingTracks.length > 0 ? colors.accent : 'gray',
						marginTop: 0,
						marginBottom: 5
					}}
					isDisabled={updatedNonExistingTracks.length <= 0}
				/>
			</View>
			{requestCompletedMessage !== '' && (
				<View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', }}>
					<Text style={[modalStyle.sublabel, { marginTop: 0, marginLeft: '10%' }]}>{requestCompletedMessage}</Text>
				</View>
			)}

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={undefined}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</View>
	)
}
